import { Spinner } from "./Spinner";

declare global {
    interface IOri {
        spinner?: typeof Spinner;
    }
}

(() => {
    ori.spinner = Spinner;
})();
